<template>
  <Hero
    heroTitle="Create resumes <br class='hidden xl:inline-block' /> that get you hired"
    heroDesc="Say Goodbye to Job Search Frustrations. Resumes so Compelling, They're Irresistible. Achieve more with a resume that displays your strengths, works with recruiting software, and earns you interviews."
    heroBtnText="Get a new CV"
    heroBtnLink="#packages"
    heroImg="hero11.png"
  />

  <Customers />

  <div class="flex justify-center bg-white">
    <div class="w-full lg:w-[78vw] px-[24px] md:px-6">
      <div class="py-[5rem]">
        <div class="mb-8">
          <h2 class="text-center mb-8 text-[32px] md:text-[60px] text-brand-black leading-[110%] font-semibold">
            Custom-Tailored Resumes With Individual <br class="hidden xl:inline-block" />
            Attention
          </h2>
          <p class="text-light-grey/50 text-[18px] md:text-[30px] text-center mb-[3rem]">
            Elevate Your Job Search with Tailor-Made Resumes and get noticed by employers with professionally crafted resumes designed to highlight
            your individuality
          </p>
        </div>

        <div class="md:grid md:grid-cols-2 lg:grid-cols-3 gap-8 pb-5">
          <div class="mb-4 md:mb-0 rounded-lg p-8">
            <div class="w-[64px] h-[64px] flex items-center justify-center rounded-full bg-brand-black/10 mb-4">
              <img src="@/assets/icons/users.svg" class="w-[40px] h-[40px]" alt="" />
            </div>
            <h1 class="font-bold text-[18px] md:text-[20px]">Not landing job interviews?</h1>
            <p class="leading-[140%]">
              You might have the right skills, but your generic or outdated resume just isn’t showing them! That’s where we come in.
            </p>
          </div>

          <div class="mb-4 md:mb-0 rounded-lg p-8">
            <div class="w-[64px] h-[64px] flex items-center justify-center rounded-full bg-brand-black/10 mb-4">
              <img src="@/assets/icons/message.svg" class="w-[40px] h-[40px]" alt="" />
            </div>
            <h1 class="font-bold text-[18px] md:text-[20px]">Our successes</h1>
            <p class="leading-[140%]">We’ve equipped thousands of professionals with better resumes, cover letters, and LinkedIn profiles.</p>
          </div>

          <div class="mb-4 md:mb-0 rounded-lg p-8">
            <div class="w-[64px] h-[64px] flex items-center justify-center rounded-full bg-brand-black/10 mb-4">
              <img src="@/assets/icons/document.svg" class="w-[40px] h-[40px]" alt="" />
            </div>
            <h1 class="font-bold text-[18px] md:text-[20px]">We’ll write and you’ll love it</h1>
            <p class="leading-[140%]">
              Unlike budget services, we have a unique process and exclusive team of highly trained writers with a special focus on collaboration.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-[#060505] text-white">
    <div class="w-full pl-[24px] md:pl-[100px]">
      <div class="pt-[3rem]">
        <div class="md:flex gap-8">
          <div class="mb-4 md:mb-0 rounded-lg p-8">
            <br />
            <br />
            <h1 class="font-bold text-[18px] md:text-[28px] mb-4">Get found on LinkedIn</h1>
            <p class="leading-[29px] text-[18px] mb-6">
              Leverage the Power of LinkedIn to advance your Career <br />
              with our Expert Profile Writing Service. Grow your network <br />
              and get noticed by more employers with our professional <br />
              LinkedIn strategies.
            </p>

            <ul class="mb-10">
              <li class="text-[#CDCDCD]/60 text-[16px] mb-3 flex items-center">
                <img src="@/assets/icons/check-sm.svg" class="mr-3" alt="" /> Maximize your Visibility
              </li>
              <li class="text-[#CDCDCD]/60 text-[16px] mb-3 flex items-center">
                <img src="@/assets/icons/check-sm.svg" class="mr-3" alt="" /> Showcase your Expertise
              </li>
              <li class="text-[#CDCDCD]/60 text-[16px] mb-3 flex items-center">
                <img src="@/assets/icons/check-sm.svg" class="mr-3" alt="" /> Build your Personal Brand
              </li>
              <li class="text-[#CDCDCD]/60 text-[16px] mb-3 flex items-center">
                <img src="@/assets/icons/check-sm.svg" class="mr-3" alt="" /> Stay ahead of the Competition
              </li>
              <li class="text-[#CDCDCD]/60 text-[16px] mb-3 flex items-center">
                <img src="@/assets/icons/check-sm.svg" class="mr-3" alt="" /> Make Lasting Connections
              </li>
            </ul>

            <a
              href="https://paystack.com/buy/linkedinoptimization"
              target="_blank"
              class="bg-white text-brand-black text-center py-4 px-6 rounded-lg font-semibold text-[18px]"
              >Get Started</a
            >
          </div>

          <div class="w-full md:w-[54vw] ml-0 md:ml-auto">
            <img src="@/assets/images/chrome.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex justify-center bg-light-yellow">
    <div class="w-full lg:w-[85vw] xl:w-[72vw] px-[24px] md:px-6">
      <div class="py-[5rem]">
        <div class="mb-8">
          <h2 class="text-center mb-2 text-[32px] md:text-[50px] text-brand-black leading-[110%] font-semibold">
            Why our customers love our resume writing service
          </h2>
          <p class="text-light-grey/50 text-[18px] md:text-[25px] text-center mb-[3rem]">
            Our track record speaks for itself. Our resume writing service has helped countless individuals secure interviews and land their dream
            jobs, giving our customers the confidence and edge they need.
          </p>
        </div>

        <div class="block md:flex justify-between items-center gap-16">
          <div class="w-full md:w-1/2 mb-8 md:mb-0">
            <img src="@/assets/images/complogo.png" class="desktopcomplogo" />
            <p class="text-[18px] md:text-[27px] leading-[110%] text-[#1A0004] my-6">
              I was initially skeptical, but Careerbuddy completely overhauled my CV, making it crisp, professional, and, above all, reflective of me!
              I've now landed my dream job at Jumia, and it's all thanks to the amazing team at Careerbuddy.
            </p>
            <h3 class="text-brand-black text-[18px] font-medium">Kwame Acheampong</h3>
            <h4 class="text-[16px] text-[#5F4D4F]">Jumia</h4>

            <div class="bottom flex items-center md:gap-6 mt-16">
              <div class="flex gap-2">
                <img src="@/assets/icons/nav_left.png" />
                <img src="@/assets/icons/nav_right.png" />
              </div>
              <div class="h-[1px] bg-[#E7EAF4] w-full" />
            </div>
          </div>

          <div class="w-full md:w-auto">
            <img src="@/assets/images/woman_in_dress.png" alt="" class="rounded-xl md:flex imgDesktop" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <Pricing id="packages" />

  <faqs :faqs="faqs" />

  <div class="flex justify-center bg-white">
    <div class="w-full px-[24px] md:px-0 md:w-[78vw] flex items-center justify-center">
      <div class="py-[8rem] text-brand-black text-center">
        <h1 class="text-brand-black text-[32px] md:text-[60px] leading-[110%] font-semibold">Subscribe to our newsletter</h1>

        <p class="text-[18px] md:text-[25px] leading-[110%] mb-8 text-brand-black/50">
          Get to know about top open jobs in Africa’s fastest-growing startups before anyone else. Every week, <br class="hidden md:inline-block" />
          we'll send you all the top jobs in startups across Africa.
        </p>
        <br />
        <div class="block w-[90vw] mx-auto md:w-[320px] items-center">
          <iframe
            src="https://careerbuddynewsletter.substack.com/embed"
            width="100%"
            height="170"
            style="border: 1px solid #eee; background: white"
            frameborder="0"
            scrolling="no"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import Hero from '@/components/partials/Hero.vue';
import Customers from '@/components/partials/Customers.vue';
import CreateAccount from '@/components/partials/CreateAccount.vue';
import Faqs from '@/components/partials/Faqs.vue';
import Pricing from '@/components/Pricing.vue';
import { useMeta } from '@/utils/meta';

const faqs = ref([
  {
    title: 'How long does it take?',
    desc: `<p>2-3 days to receive the first review not final draft</p>`,
  },
  {
    title: 'Can I discuss a review after delivery?',
    desc: `<p>Yes, we will review your CV with you until you are completely satisfied.</p>`,
  },
  {
    title: 'What is your refund policy?',
    desc: `<p>All sales are final, but there will be multiple drafts and revisions to ensure your satisfaction. Careerbuddy CV writers will work with you throughout the revision period until you are satisfied with your CV.</p>`,
  },
  {
    title: 'Do I really need a LinkedIn?',
    desc: `<p>In a short answer - YES, if you are in the market for a job, it is highly recommended that you have a strong LinkedIn presence. Recruiters are avtively using LinkedIn to search for candidates with their skill set every single day.</p>`,
  },
]);
useMeta();
</script>
