<template>
  <div class="flex justify-center bg-white">
    <div class="w-full lg:w-[85vw] xl:w-[72vw] px-[24px] md:px-6">
      <div class="py-[5rem]">
        <div class="mb-8">
          <h2 class="text-center mb-2 text-[32px] md:text-[50px] text-brand-black leading-[110%] font-semibold">Affordable Pricing for Everyone</h2>
          <p class="text-light-grey/50 text-[18px] md:text-[25px] text-center mb-[3rem]">
            Unlock your career potential with a one-time payment for our personalized resume writing service - no recurring fees, cancel anytime.
          </p>
        </div>

        <div class="md:grid grid-cols-3 gap-10 pb-5">
          <div class="border rounded mb-8 rounded-tl-3xl rounded-tr-3xl pricing" v-for="(data, index) in pricingData" :key="data.id">
            <div class="rounded-tl-3xl rounded-tr-3xl p-6 py-8">
              <h2 class="font-semibold text-[28px] text-[#FFFFFF]">{{ data.type }}</h2>
              <p class="text-[#CECECE] text-[16px]">{{ data.details }}</p>
            </div>
            <div class="p-6">
              <h2 class="text-[#000000] text-[28px] font-semibold my-6">{{ data.price }}</h2>
              <h3 class="text-[#000000] text-[18px] font-semibold mb-2">All the benefits include:</h3>
              <ul class="list-disc">
                <li class="mb-2 font-medium text-[##4D4D4D] text-[15px]" v-for="benefit in data.benefits" :key="benefit">{{ benefit }}</li>
              </ul>
              <div class="link mt-8">
                <br />
                <a :href="data.link" target="_blank" class="bg-[#FF2449] text-white block text-center p-4 rounded-lg font-semibold text-[18px]"
                  >Get this</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  el: '#users',
  data() {
    return {
      benefits: [
        "Formatted for success - Formatting that will get an employer's attention.",
        'Keyword optimized - Your CV will be optimized to pass through Applicant Tracking Systems.',
        '60 Hours Turn around',
        '1 Professionally Written Resume in editable Word & PDF Document',
        'CV Review With Optimization Score',
      ],
      users: [
        { name: 'Foo Bar', email: 'foo@bar.com' },
        { name: 'John Doh', email: 'john@doh.com' },
      ],
      pricingData: [
        {
          id: 0,
          type: 'Pro',
          details: 'For candidate with 3 - 7 years of working experience in their fields',
          price: 'NGN 20,000',
          link: 'https://paystack.com/buy/cv-writing-pro',
          benefits: [
            "Formatted for success - Formatting that will get an employer's attention.",
            'Keyword optimized - Your CV will be optimized to pass through Applicant Tracking Systems.',
            '5 working days after CV resource is shared',
            '1 Professionally Written Resume in editable Word & PDF Document',
          ],
        },
        {
          id: 1,
          type: 'Premium',
          details: 'For candidate with 8 - 15 years of working experience in their fields',
          price: 'NGN 30,000',
          link: 'https://paystack.com/buy/cv-writing-premium1',
          benefits: [
            '3 working days after CV resource is shared',
            "Formatted for success - Formatting that will get an employer's attention.",
            'Keyword optimized - Your CV will be optimized to pass through Applicant Tracking Systems.',
            '1 Professionally Written Resume in editable Word & PDF Documents',
          ],
        },
        {
          id: 2,
          type: 'Career Accelerator Kit “Get a job 3x faster”',
          details: 'For candidates with 0-3 years experience who want to accelerate their ability to access job opportunities.',
          price: 'NGN 30,000',
          link: 'https://paystack.com/buy/careeraccelerator-basic-bnehgf',
          benefits: [
            '5 working days after CV resource is shared',
            "Formatted for success - Formatting that will get an employer's attention.",
            'Keyword optimized - Your CV will be optimized to pass through Applicant Tracking Systems.',
            '1 Professionally Written Resume in editable Word & PDF Documents',
            'LinkedIn Profile Optimization',
            '2 sessions with a Career coach'
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.pricing {
  &:first-child, &:nth-child(5) {
    & > div {
      &:first-child {
        background-color: #060505;
      }
    }
  }

  &:nth-child(2) {
    & > div {
      &:first-child {
        background-color: #bd1531;

        p {
          color: #fff;
        }
      }
    }
  }

  &:nth-child(3) {
    & > div {
      &:first-child {
        background-color: #18a2e2;

        h2 {
          color: white;
        }

        p {
          color: white;
        }
      }
    }
  }

  &:nth-child(4) {
    & > div {
      &:first-child {
        background-color: #ff8803;

        h2 {
          color: white;
        }

        p {
          color: white;
        }
      }
    }
  }
}
</style>
